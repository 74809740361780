<template>
  <div class="flex">
    <div class="w-2/5">
      <label class="block mb-6">
        <ul class="rounded border border-gray-200 shadow">
          <li
            v-for="(period, key) in localPeriods"
            :key="key"
            class="border-b border-gray-200 py-2 px-4 text-gray-800 font-bold flex justify-between items-center"
          >
            <div>{{ period.pt.title }}</div>
            <div class="button bg-blue-600" @click.prevent="editing = key">
              Editar
            </div>
          </li>
        </ul>
      </label>
    </div>

    <div class="w-1/2 ml-4" v-if="editing">
      <form @submit.prevent="savePeriods()" class="pl-4">
        <div v-for="lang in languages" :key="lang.abbreviation" class="mb-10">
          <label class="block mb-4">
            <span class="label">Título {{ lang.title }}</span>
            <input
              type="text"
              class="input"
              v-model="localPeriods[editing][lang.abbreviation].title"
              placeholder="Português"
            />
          </label>

          <label class="block mb-4">
            <span class="label">Descrição {{ lang.title }}</span>
            <textarea
              class="input"
              v-model="localPeriods[editing][lang.abbreviation].description"
            ></textarea>
          </label>
        </div>

        <div
          v-if="message"
          class="bg-green-300 border rounded shadow mb-4 border-green-700 text-green-800 px-3 py-2 font-bold"
        >
          {{ message }}
        </div>
        <div
          v-if="errorMessage"
          class="bg-red-300 border rounded shadow mb-4 border-red-700 text-red-800 px-3 py-2 font-bold"
        >
          {{ errorMessage }}
        </div>

        <AppButton :loading="loading">Salvar</AppButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppButton from "@/components/AppButton";

export default {
  name: "Periods",
  components: {
    AppButton
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: false,
      localPeriods: null,
      editing: null,

      message: null,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      periods: state => state.Settings.periods,
      languages: state => state.Settings.languages
    })
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {
    editing() {
      this.loading = false;
      this.message = null;
      this.errorMessage = null;
    },
    phases: {
      handler() {
        const localPeriods = { ...this.periods };

        this.languages.forEach(item => {
          for (let key in localPeriods) {
            if (!localPeriods[key][item.abbreviation]) {
              localPeriods[key][item.abbreviation] = {
                title: "",
                description: ""
              };
            }
          }
        });

        this.localPeriods = localPeriods;
      },
      deep: true
    }
  },
  async created() {
    await this.$store.dispatch("Settings/list");

    const localPeriods = { ...this.periods };

    this.languages.forEach(item => {
      for (let key in localPeriods) {
        if (!localPeriods[key][item.abbreviation]) {
          localPeriods[key][item.abbreviation] = {
            title: "",
            description: ""
          };
        }
      }
    });

    this.localPeriods = localPeriods;
  },
  async mounted() {},
  methods: {
    async savePeriods() {
      this.loading = true;
      this.message = null;
      this.errorMessage = null;

      const response = await this.$store.dispatch("Settings/update", [
        "periods",
        {
          periods: { ...this.periods }
        }
      ]);

      if (response) {
        this.message = "Salvo com sucesso!";
      } else {
        this.errorMessage = "Ops, deu pau, fala com o Edu!";
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
